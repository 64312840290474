<template>
  <div class="standard-page">
    <!-- The header buttons -->
    <div class="d-flex justify-space-between mb-4">
      <!-- The link back to the index -->
      <v-btn text color="primary" @click="$router.replace({ name: 'InfluencerInsightsExport' })">
        <v-icon left> arrow_back </v-icon>

        Back
      </v-btn>

      <!-- The button to delete selected items -->
      <v-btn
        v-if="selectedItems.length"
        depressed
        color="primary"
        :loading="isMakingRequest"
        :disabled="isMakingRequest"
        @click="shouldShowDeleteDialog = true"
      >
        <v-icon left> delete </v-icon>

        Delete
      </v-btn>
    </div>

    <v-card class="box-shadow-soft rounded rounded-bl-0 rounded-br-0 px-3 py-6">
      <v-row>
        <!-- Show the selectors -->
        <v-col
          cols="12"
          sm="6"
          lg="8"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="4"
            >
              <platform-selector
                v-model="selectedPlatforms"
                label="Platforms"
                small
                outlined
                full-width
                allow-multiple
              />
            </v-col>

            <v-col
              cols="6"
              md="3"
            >
              <v-select
                v-model="selectedStatus"
                label="Status"
                outlined
                dense
                hide-details
                :items="statusOptions"
              ></v-select>
            </v-col>

            <v-col
              cols="6"
              md="3"
            >
              <v-select
                v-model="selectedType"
                label="Type"
                outlined
                dense
                hide-details
                :items="typeOptions"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>

        <v-spacer />

        <!-- Show the input -->
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-text-field
            v-model="searchQuery"
            label="Search"
            placeholder="Username, Account ID or Fullname"
            outlined
            dense
            hide-details
            clearable
            append-icon="search"
            @keypress.enter="currentPage = 1"
            @click:append="currentPage = 1"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card>

    <!-- Show the results table -->
    <v-data-table
      v-model="selectedItems"
      :headers="tableHeaders"
      :items="response.data"
      :server-items-length="response.total"
      :page="currentPage"
      :items-per-page="queryPerPage"
      @update:page="currentPage = $event"
      @update:items-per-page="queryPerPage = $event"
      show-select
      selectable-key="id"
      :mobile-breakpoint="100"
      class="box-shadow-soft influencer-insight-history-data-table"
    >
      <!-- For Platform -->
      <template v-slot:item.platforms="{ item }">
        <div class="d-flex">
          <v-img
            v-for="platform in item.platforms"
            :key="platform"
            height="30"
            width="30"
            class="mr-2"
            max-width="30"
            :src="platformIconMap[platform]"
          />
        </div>
      </template>

      <!-- For Title -->
      <template v-slot:item.title="{ item }">
        <div>
          {{ item.title }}
        </div>
      </template>

      <!-- For Status -->
      <template v-slot:item.status="{ item }">
        <status-chip
          :status="item.status"
        />
      </template>

      <!-- For created at -->
      <template v-slot:item.created_at="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              class="d-flex flex-column"
              v-bind="attrs"
              v-on="on"
            >
              <!-- Show the date -->
              <div class="white-space-nowrap">
                {{ formatRelativeDate(item.created_at, "MMMM D, YYYY") }}
              </div>

              <!-- If it's more than 7 days -->
              <div v-if="differenceFromNow(item.created_at) > 7">
                {{ formatRelativeDate(item.created_at, "h:mm A") }}
              </div>
            </div>
          </template>
          <span>
            {{ formatDate(item.created_at) }}
          </span>
        </v-tooltip>
      </template>

      <!-- To show a button for the graph -->
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-end align-center">
          <v-btn
            small
            depressed
            width="170"
            color="primary"
            @click="$router.push(computeRouteObject(item))"
            :disabled="item.items_count > constants.model.influencerInsightExport.maximumItems"
          >
            Download {{ item.type === 'sheet' ? 'XLSX' : 'ZIP' }}
            <v-icon small right> open_in_new </v-icon>
          </v-btn>

          <!-- Show menu options -->
          <v-menu
            transition="slide-y-transition"
            :close-on-content-click="true"
            offset-y
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="ml-3"
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <!-- Show the menu options -->
            <v-list width="170" dense>
              <v-list-item
                @click="showEditDialog(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Rename
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                :disabled="item.items_count > constants.model.influencerInsightExport.maximumItems"
                @click="$router.push(computeRouteObject(item, item.type === 'sheet' ? 'zip' : 'sheet'))"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Download as {{ item.type === 'sheet' ? 'ZIP' : 'XLSX' }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                @click="showItemsDialog(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    View Influencers
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
<!--

              <v-list-item :disabled="item.items_count > constants.model.influencerInsightExport.maximumItems">
                <v-list-item-content>
                  <v-list-item-title>
                    Add To Group
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item :disabled="item.items_count > constants.model.audienceOverlapReport.maximumItems">
                <v-list-item-content>
                  <v-list-item-title>
                    Check Overlap
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->

              <v-list-item
                @click="showDeleteDialog(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Delete
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>

    <!-- Show the delete confirmation dialog -->
    <v-dialog
      v-model="shouldShowDeleteDialog"
      :persistent="isMakingRequest"
      max-width="400"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <v-icon dark class="mr-3"> delete </v-icon>

          Are you sure?
        </v-card-title>

        <v-card-text class="pt-4">
          You are about to delete {{ selectedItems.length }} {{ selectedItems.length > 1 ? "exports" : "export" }}.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            :disabled="isMakingRequest"
            @click="shouldShowDeleteDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            text
            color="primary"
            :loading="isMakingRequest"
            :disabled="isMakingRequest"
            @click="handleDeleteSubmit"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show the edit dialog -->
    <v-dialog
      v-model="shouldShowEditDialog"
      max-width="400"
    >
      <v-card v-if="selectedItem">
        <v-card-title class="d-flex justify-space-between primary white--text pb-4">
          <div class="d-flex align-center">
            Edit this export
          </div>
        </v-card-title>

        <v-card-text class="pt-6">
          <v-text-field
            v-model="form.title"
            label="Export Title"
            placeholder="Optional"
            @input="$v.form.title.$touch()"
            @blur="$v.form.title.$touch()"
            :hide-details="!$v.form.title.$anyError"
            :error-messages="$v.form.title.$anyError ? ['Please enter a title under 100 characters'] : null"
            class="mb-6"
            outlined
            dense
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            @click="shouldShowEditDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            depressed
            color="primary"
            @click="handleEditSubmit"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show the dialog for detailed view -->
    <v-dialog
      v-model="shouldShowItemsDialog"
      max-width="400"
      scrollable
    >
      <v-card>
        <v-card-title class="primary white--text">
          Influencers in this export
        </v-card-title>

        <v-card-text class="pt-4">
          <!-- Show the overview -->
          <div v-if="detailedResponse">
            <v-chip-group>
              <status-chip
                v-for="item in detailedResponse.items"
                v-if="item.count > 0"
                :key="item.status"
                :status="item.status"
                :apply-color="item.status === selectedItemStatus"
                @click="handleSelectedItemStatusChange(item.status)"
              >
                &nbsp;({{ item.count }})
              </status-chip>
            </v-chip-group>
          </div>

          <!-- Show the list -->
          <div v-if="detailedItemsResponse">
            <!-- If the count is 0 -->
            <div
              v-if="detailedItemsResponse.data.length === 0"
              class="text-center"
            >
              No influencers
            </div>

            <!-- Otherwise -->
            <v-list
              v-else
              dense
            >
              <template v-for="(item, index) in detailedItemsResponse.data">
                <v-list-item
                  :key="item.id"
                >
                  {{ item.account_id }}
                </v-list-item>

                <!-- Show a divider if possible -->
                <v-divider v-if="index < detailedItemsResponse.data.length"/>
              </template>

              <!-- Show the pagination -->
              <v-pagination
                v-if="detailedItemsResponse.last_page > 1"
                :value="detailedItemsResponse.current_page"
                :length="detailedItemsResponse.last_page"
                :total-visible="5"
                @input="fetchDetailedItemsResponse"
                class="mt-4"
              />
            </v-list>
          </div>
          <!-- Otherwise -->
          <div
            v-else
            class="text-center pt-4"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            @click="shouldShowItemsDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import helper functions
import messageEvents from "@/helpers/messageEvents"
import { getClientID } from "@/helpers/clientHelper"
import { maxLength } from "vuelidate/lib/validators"

// Import child components
const StatusChip = () => import(/* webpackChunkName: "status-chip" */ "@/blocks/common/StatusChip")
const PlatformSelector = () => import(/* webpackChunkName: "platform-selector" */ "@/blocks/common/selectors/PlatformSelector")

// The subscriptionId for message events
const subscriptionId = Symbol("InfluencerInsight/History")

// Export this SFC
export default {
  // Name of this component
  name: "InfluencerInsightExportHistory",

  // Register the child components
  components: {
    StatusChip,
    PlatformSelector
  },

  // Local data variables
  data: () => ({
    // Whether or not we're loading
    isMakingRequest: false,
    isMakingDetailedRequest: false,
    isMakingDetailedItemsRequest: false,

    // The selected items to be deleted
    selectedItems: [],
    shouldShowDeleteDialog: false,

    // The selected item to be used with various options
    selectedItem: null,
    shouldShowEditDialog: false,
    shouldShowItemsDialog: false,

    // The triggerIDs to be shown toasts about
    triggerIDs: [],

    // The result table headers
    tableHeaders: [
      {
        text: "",
        value: "platforms",
        sortable: false
      },
      {
        text: "Created At",
        value: "created_at",
        sortable: false,
      },
      {
        text: "Title",
        value: "title",
        sortable: false,
        width: 160
      },
      {
        text: "Influencers",
        value: "items_count",
        sortable: false,
        align: "end"
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
        align: "end"
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        align: "end"
      }
    ],

    statusOptions: [
      {
        text: "All",
        value: null
      },
      {
        text: "Pending",
        value: "pending"
      },
      {
        text: "Processing",
        value: "processing"
      },
      {
        text: "Completed",
        value: "completed"
      },
      {
        text: "Failed",
        value: "failed"
      }
    ],

    typeOptions: [
      {
        text: "All",
        value: null
      },
      {
        text: "ZIP",
        value: "zip"
      },
      {
        text: "XLSX",
        value: "sheet"
      },
    ],

    // The edit form
    form: {
      title: "",
    },

    // Status options for items
    itemStatusOptions: [
      "error",
      "pending",
      "ignored",
      "success"
    ],

    // The current selected status option
    selectedItemStatus: "success",

    // List to be shown for items
    detailedResponse: null,
    detailedItemsResponse: null
  }),

  // Define local computable properties
  computed: {
    /**
     * Get API response from the Vuex store
     *
     * @returns {Object}
     */
    response() {
      return this.$store.getters["influencerInsight/exportResponse"]
    },

    /**
     * Get the queries from Vuex store
     *
     * @returns {Object}
     */
    query() {
      return this.$store.getters["influencerInsight/exportQuery"]
    },

    /**
     * Get the current page number in pagination
     */
    currentPage: {
      get() {
        return this.query.page
      },

      set(value) {
        this.$store.dispatch("influencerInsight/updateExportQuery", {
          key: "page",
          value
        })

        // Dispatch action to fetch items
        this.fetchItems()
      }
    },

    /**
     * Get the number of items per page
     */
    queryPerPage: {
      get() {
        return this.query.perPage
      },

      set(value) {
        this.$store.dispatch("influencerInsight/updateExportQuery", {
          key: "perPage",
          value
        })

        // Dispatch action to fetch items
        this.fetchItems()
      }
    },

    /**
     * Get the selected status
     */
    selectedStatus: {
      get() {
        return this.query.status
      },

      set(value) {
        this.$store.dispatch("influencerInsight/updateExportQuery", {
          key: "status",
          value
        })

        // Dispatch action to fetch items
        this.fetchItems()
      }
    },

    /**
     * Get the selected type
     */
    selectedType: {
      get() {
        return this.query.type
      },

      set(value) {
        this.$store.dispatch("influencerInsight/updateExportQuery", {
          key: "type",
          value
        })

        // Dispatch action to fetch items
        this.fetchItems()
      }
    },

    /**
     * Get the selected platform
     */
    selectedPlatforms: {
      get() {
        return this.query.platforms
      },

      set(value) {
        this.$store.dispatch("influencerInsight/updateExportQuery", {
          key: "platforms",
          value
        })

        // Dispatch action to fetch items
        this.fetchItems()
      }
    },

    /**
     * Get the search query
     */
    searchQuery: {
      get() {
        return this.query.search
      },

      set(value) {
        // If the value is same
        if (this.query.search === value) return

        this.$store.dispatch("influencerInsight/updateExportQuery", {
          key: "search",
          value
        })
      }
    }
  },

  // Define vuelidate validation rules
  validations: {
    form: {
      title: {
        maxLength: maxLength(100)
      }
    }
  },

  // Method functions to be called from template and script
  methods: {
    /**
     * Fetch the items
     *
     * @returns {void}
     */
    async fetchItems() {
      // If the request is already being made
      if (this.isMakingRequest) return

      // Mark as loading
      this.isMakingRequest = true
      // Dispatch action to fetch items
      await this.$store.dispatch("influencerInsight/fetchExportItems")
      // Mark as not loading
      this.isMakingRequest = false
    },

    /**
     * Show the delete dialog
     *
     * @param {Object} item
     * @returns {void}
     */
    showDeleteDialog(item) {
      this.selectedItems = [item]
      this.shouldShowDeleteDialog = true
    },

    /**
     * Delete the selected items
     *
     * @returns {void}
     */
    async handleDeleteSubmit() {
      // If the request is already being made
      if (this.isMakingRequest) return

      // Show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Go through each selected items
      for (const item of this.selectedItems) {
        // Use vuex store action
        await this.$store.dispatch("influencerInsight/deleteExportItem", {
          id: item.id,
          showToast: false,
          fetchItems: false
        })
      }

      // Hide the loader
      this.$store.dispatch("loaders/remove", loaderId)
      this.isMakingRequest = false

      // Reset the selected items
      this.selectedItems = []

      // Hide the dialog
      this.shouldShowDeleteDialog = false

      // Refresh the items
      this.fetchItems()
    },

    /**
     * Show the edit dialog
     *
     * @param {Object} item
     * @returns {void}
     */
    showEditDialog(item) {
      // Set the form values
      this.form.title = item.title || ""

      this.$v.form.$reset()

      this.selectedItem = item
      this.shouldShowEditDialog = true
    },

    /**
     * Handle the edit action
     *
     * @returns {void}
     */
    async handleEditSubmit() {
      // If the request is already being made
      if (this.isMakingRequest) return

      // Close the dialog
      this.shouldShowEditDialog = false
      this.isMakingRequest = true

      // Use vuex store action
      await this.$store.dispatch("influencerInsight/updateExportItem", {
        id: this.selectedItem.id,
        title: this.form.title
      })

      this.isMakingRequest = false
    },

    /**
     * Show the dialogs for detailed response
     *
     * @param {Object} item
     * @returns {void}
     */
    showItemsDialog(item) {
      // Mark as selected
      this.selectedItem = item

      // Reset the state
      this.detailedResponse = null
      this.detailedItemsResponse = null
      this.selectedItemStatus = "success"

      // Fetch the response
      this.fetchDetailedResponse()

      // Show the dialog
      this.shouldShowItemsDialog = true
    },

    /**
     * Get detailed response for a particular export
     *
     * @returns {void}
     */
    async fetchDetailedResponse() {
      // Show the loader
      this.isMakingDetailedRequest = true

      // Try making network request
      try {
        // Get the response
        const response = await axios({
          url: `/api/influencer-insight-exports/${this.selectedItem.id}`
        })

        // Update the value
        this.detailedResponse = response.data

        // Fetch the items
        this.fetchDetailedItemsResponse()
      }
      // Catch any error
      catch (error) {
        logger({ error, type: "InfluencerInsight/ExportHistory/fetchDetailedResponse" })

        // Show the toast
        this.$store.dispatch("toasts/add", { text: "An error occurred" })

        // Hide the dialog
        this.shouldShowItemsDialog = false
      }
      // Nonetheless
      finally {
        this.isMakingDetailedRequest = false
      }
    },

    /**
     * Update selected status value for items and refetch
     *
     * @param {String} status
     * @returns {void}
     */
    async handleSelectedItemStatusChange(status) {
      this.selectedItemStatus = status

      // Fetch the items response
      this.fetchDetailedItemsResponse()
    },

    /**
     * Get paginated items data for an export
     *
     * @returns {void}
     */
    async fetchDetailedItemsResponse(page = 1) {
      // Show the loader
      this.isMakingDetailedItemsRequest = true

      // Try making network request
      try {
        // Get the response
        const response = await axios({
          url: `/api/influencer-insight-exports/${this.selectedItem.id}/${this.selectedItemStatus}?page=${page}`
        })

        // Update the value
        this.detailedItemsResponse = response.data
      }
      // Catch any error
      catch (error) {
        logger({ error, type: "InfluencerInsight/ExportHistory/fetchDetailedItemsResponse" })

        // Show the toast
        this.$store.dispatch("toasts/add", { text: "An error occurred" })
      }
      // Nonetheless
      finally {
        this.isMakingDetailedItemsResponse = false
      }
    },

    /**
     * Handle the message event
     *
     * @param {Object} event
     * @returns {void}
     */
    handleMessageEvent(event) {
      // Go through the possible values
      switch (event.key) {
        case "generate-influencer-insight-pdf-started":
          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "Generating your PDF ...", timeout: 4000 })

          break

        case "generate-influencer-insight-xlsx-started":
          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "Generating your XLSX ...", timeout: 4000 })

          break

        case "generate-influencer-insight-pdf-completed" || "generate-influencer-insight-xlsx-completed":
          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "Downloading your report" })

          break

        case "generate-influencer-insight-pdf-failed" || "generate-influencer-insight-xlsx-failed":
          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "Your download failed" })

          break
      }
    },

    /**
     * Compute the vue-router relevant object
     *
     * @param {Object} item
     * @param {String|Null} type
     * @returns {Object}
     */
    computeRouteObject(item, type = null) {
      const object = {
        name: "InfluencerInsightsExport",
        query: {
          exportId: item.id
        }
      }

      // If the value exists
      if (type) {
        object.query.exportType = type
      }

      return object
    }
  },

  /**
   * As soon as the component is rendered
   *
   * @returns {void}
   */
  created() {
    // Fetch the items
    this.fetchItems()

    // Register a subscriber for messageEvents
    messageEvents.register({
      id: subscriptionId,
      module: "influencer-insight-report",
      type: "all",
      key: "all",
      validator: (event) => event.module === "influencer-insight-report" && event.localData.clientId === getClientID(),
      callback: this.handleMessageEvent
    })
  },

  /**
   * As soon as the component is destroyed
   *
   * @returns {void}
   */
  beforeDestroy() {
    // Unregister the subscriber for messageEvents
    messageEvents.deregister(subscriptionId)
  }
}
</script>

<style lang="stylus" scoped>
.contain-select-width
  width 12em
</style>

<style lang="stylus">
.items-preview
  &__images
    position relative
    width 50px

    img
      width 40px
      height 40px
      border-radius 100%
      position absolute
      transform scale(0.95)
      border 2px solid white
      top -20px

      &:first-child
        top -22px
        left 10px
        z-index 2

  &__title
    margin-top -2px
    margin-bottom -6px

.influencer-insight-history-data-table thead tr th
  white-space nowrap !important
</style>
